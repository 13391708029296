import React from "react"

export default function Formular() {
    return (
        <form action="#" method="POST" className="relative w-full mt-9 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
            <div>
                <label htmlFor="first-name" className="block text-sm text-gray-900 prose font-light">
                    Vorname
                </label>
                <div className="mt-1">
                    <input
                        type="text"
                        placeholder="Mara"
                        name="first-name"
                        id="first-name"
                        autoComplete="given-name"
                        className="block w-full sm:text-sm bg-transparent border-transparent border-b-gray-700 border-b-2 px-0 placeholder:text-gray-700"
                    />
                </div>
            </div>
            <div>
                <label htmlFor="last-name" className="block text-sm text-gray-900 prose font-light">
                    Name
                </label>
                <div className="mt-1">
                    <input
                        type="text"
                        placeholder="Musterfrau"
                        name="last-name"
                        id="last-name"
                        autoComplete="family-name"
                        className="block w-full  sm:text-sm bg-transparent border-transparent border-b-gray-700 border-b-2 px-0 placeholder:text-gray-700"
                    />
                </div>
            </div>
            <div className="sm:col-span-2">
                <label htmlFor="email" className="block text-sm text-gray-900 prose font-light">
                    E-Mail*
                </label>
                <div className="mt-1">
                    <input
                        id="email"
                        placeholder="mara@musterfrau.nett"
                        name="email"
                        type="email"
                        autoComplete="email"
                        className="block w-full  sm:text-sm bg-transparent border-transparent border-b-gray-700 border-b-2 px-0 placeholder:text-gray-700"
                    />
                </div>
            </div>
            <div className="sm:col-span-2">
                <div className="flex justify-between">
                    <label htmlFor="phone" className="block text-sm text-gray-900 prose font-light">
                        Telefon*
                    </label>
                </div>
                <div className="mt-1">
                    <input
                        type="text"
                        placeholder="079 hed si gseid ..."
                        name="phone"
                        id="phone"
                        autoComplete="tel"
                        aria-describedby="phone-description"
                        className="block w-full  sm:text-sm bg-transparent border-transparent border-b-gray-700 border-b-2 px-0 placeholder:text-gray-700"
                    />
                </div>
            </div>
            <div className="sm:col-span-2">
                <div className="flex justify-between">
                    <label htmlFor="how-can-we-help" className="block text-sm text-gray-900 prose font-light">
                       Was ich dir sagen wollte
                    </label>
                </div>
                <div className="mt-1">
                    <textarea
                        id="how-can-we-help"
                        placeholder="Don't be shy!"
                        name="how-can-we-help"
                        aria-describedby="how-can-we-help-description"
                        rows={4}
                        className="block w-full  sm:text-sm bg-transparent border-transparent border-b-gray-700 border-b-2 px-0 placeholder:text-gray-700"
                        defaultValue={''}
                    />
                </div>
            </div>
            <div className="text-right sm:col-span-2">
                <button
                    type="submit"
                    className="inline-flex font-heading justify-center py-2 px-4 border-black text-sm font-medium  text-black border-2 hover:bg-themeLila hover:text-white hover:border-themeLila"
                >
                    Bestellen
                </button>
            </div>
        </form>
    )
}