import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";

const itemStyle = `grid sm:pt-4 pt-8 pb-2 px-4 lg:px-8 content-start`;


const CarouselItem = ({
    title, content, image, bgColor
}) => {

    return (
        <div
            className={`carousel-item ${itemStyle}`}

        // onClick={() => {
        //     onItemClick(item);
        // }}
        // onMouseOver={() => {
        //     setIsHovered(true);
        // }}
        // onMouseLeave={() => {
        //     setIsHovered(false);
        // }}
        >
            <div className={`relative flex transition-transform min-width-0 w-auto`} >
                <div className={`flex shadow-xl w-3/4 ${bgColor}`}>
                    {/* <img
                        className=""
                        src={image}
                        alt={title}
                    /> */}
                    <GatsbyImage
                        className="relative block w-full h-auto"
                        imgClassName="relative block w-full h-auto"
                        itemProp="image"
                        image={image}
                        alt={title}
                    />
                </div>
            </div>
            <div className="flex flex-col">
                <h2 className='block text-3xl text-gray-900 py-6 font-heading'>
                    {title}
                </h2>
                <div className="text-gray-900 text-justify hyphens-auto max-w-md text-lg">
                    {content}
                </div>
            </div>
        </div>
    )

}

export default CarouselItem;