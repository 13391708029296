import '../App.css';
import React from "react";
import { useRef } from "react";
import Seo from '../components/seo';
import Section2Col from '../components/Section2Col';

import { graphql, StaticQuery } from 'gatsby';

import CarouselItem from "../components/CarouselItem";
import SlickCarousel from "../components/SlickCarousel";

import Formular from '../components/Formular';
import TwoColCTA from '../components/TwoColCTA';

// https://play.tailwindcss.com/lQk1tAEWet

const Section = Section2Col


const IndexPage = () => {

  const ref = useRef(null);

  return (
    <div>
      <Seo title='Mein Buch - Laila schreibt' />
      <div className="wrapper__section">
        <StaticQuery
          query={buchQuery}
          render={({ cover, image1, image2, image3, image4, image5 }) => {

            const data = [
              {
                title: "Zöpfli",
                content: <p className="pb-4 prose font-light">
                  Ideal für zweite Dates, Feierabend-Biere oder um als Feminist*in dem Wirken von Josi-J.-Meier nachzuspüren. Ein durch und durch magischer Platz.
                </p>,
                bgColor: `bg-theme/80`,
                icon: image5
              },
              {
                title: "Bellerive Villa",
                content: <p className="pb-4 prose font-light">
                  Sie gilt als eine der prachtvollsten Privatvillen ihrer Zeit. Und trotzdem verirrt sich fast keine Menschenseele in die Villa Bellerive am Dietschiberg (ausgenommen ein paar PH-Studierende)
                </p>,
                bgColor: `bg-themeBlue`,
                icon: image1
              },
              {
                title: "Parterre",
                content: <p className="pb-4 prose font-light">
                  Wenn mich meine Freund*innen suchen, bin ich am ehesten hier anzutreffen: Am käffelen, arbeiten oder Rosé trinken im Parterre. So wie eigentlich die ganze Luzerner Neustadt.
                </p>,
                bgColor: `bg-themeRed/80`,
                icon: image3
              },
              {
                title: "Waldstätterweg",
                content: <p className="pb-4 prose font-light">
                  Eine schöne Wanderung entlang des Vierwaldstättersees. Für mich war die Etappe 7 des Waldstätterwegs sowieso ein prägendes Ereignis. Warum? Das liest du in meinem Blog.
                </p>,
                bgColor: `bg-themeDarkBlue/80`,
                icon: image4

              },
              {
                title: "Und viele mehr",
                content: <p className="pb-4 prose font-light">
                  Na, gwundrig geworden? Dann bestelle ein Buch und entdecke alle 80 Glücksorte in Luzern!
                </p>,
                bgColor: `bg-themeDarkBlue/80`,
                icon: image2

              }
            ];

            return (
              <>
                <Section
                  id="section-1"
                  title="Mein Buch."
                  gatsbyImageData={cover.childImageSharp.gatsbyImageData}
                  className = "content-grid grid-cols-2"
                  imgStyle="w-1/2 mx-auto py-24 flex self-center place-self-center justify-self-center"
                  imgWrapper='relative flex w-full h-screen'
                >
                  <p className='prose font-light'>
                    80 ausgewählte Glücksorte in und rund um die Stadt Luzern. Eine bunte Mischung aus Naturoasen, Cafés und Ausflugsideen, welche zum Verlieben, Neuentdecken und Hierbleiben verführen. Meine Freund*innen sagten immer ich soll ein Buch schreiben: Hier ist es. Inklusive Bilder.
                  </p>
                </Section>

                <div className='bg-themeLila px-4 md:px-6 py-48 snap-start'>
                  <div className='content-slim'>
                    <h2 className='py-4 text-5xl  mb-8 font-heading'>
                      Ich habe ein Buch geschrieben.
                    </h2>
                    <div className='grid grid-cols-1 md:grid-cols-2 text-xl leading-relaxed hyphens-auto text-justify gap-24'>

                      <div className='flex'>
                        <p className='prose font-light'>
                          „Vom Himmel brennt d’Sunnе. Und ich brenn für dich.“ Kein anderer Satz beschreibt meine Liebe zu Luzern besser, als Fabers Songzeile aus dem Lied «Ich liebe dich, Faber.» Ich brenne für meine Stadt und bin dankbar sie mein Zuhause nennen zu dürfen. Kein Wunder habe ich einige Freudengümp gemacht, als ich die Zusage vom Droste-Verlag für diesen Reiseführer der etwas anderen Art erhalten habe. 80 Glücksorte meiner Wahl – in und rund um Luzern. Geheimtipps treffen auf Altbekanntes, Historisches auf jungen Charme.
                        </p>
                      </div>

                      <div className='flex'>
                        <p className='prose font-light'>
                          Tatsächlich stand das Schreiben eines Buches auf meiner «Lebens-ToDo-Liste». Dass dieser Wunsch aber so schnell in Erfüllung gehen würde, hätte ich mir nie erträumen lassen. Und so habe ich mich 2021 auf die Suche nach den lieblichsten Naturoasen, gemütlichsten Cafés und postkartenreifsten Ausflügen gemacht. Ich hoffe, dass ich dir mit meinen ausgewählten Glücksorten ein Lächeln auf die Lippen zaubern kann. Viel Spaß beim Schnöiggen, Erkunden und Verlieben.
                        </p>
                      </div>

                    </div>

                  </div>
                </div>


                <div className='bg-white px-4 md:px-6 py-48 snap-start'>
                  <div className='relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl'>
                    <h2 className='py-4 text-5xl  mb-8 font-heading'>
                      Die Glücksorte aus dem Buch.
                    </h2>
                  </div>


                  <div id="technology" className="relative snap-start columns flex flex-col w-full md:justify-center flex-wrap py-12 pt-24">

                    <div className="w-full cursor-move">
                      <SlickCarousel>
                        {data.map((item, index) => (
                          <CarouselItem
                            key={index}
                            title={item.title}
                            image={item.icon.childImageSharp.gatsbyImageData}
                            bgColor={item.bgColor}
                            content={item.content}
                          >
                          </CarouselItem>
                        ))}
                      </SlickCarousel>
                    </div>
                  </div>

                </div>

              </>
            )
          }}
        />


        <div
          className='bg-[#DCAA7F] relative py-24 max-max-w-screen snap-start'>

          <div
            className='relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl grid md:grid-cols-2 gap-24'
          >


            <div className="relative flex w-full items-center ">
              <div className="mx-auto">
                <h2 className="py-4 text-5xl  mb-8 font-heading">Buch eintüten.</h2>
                <div className="flex flex-col gap-4 text-xl hyphens-auto text-justify leading-relaxed justify-center items-center">
                  <p className='prose font-light'>
                    Natürlich wäre ich glücklich wie ein junges Gämschi, wenn du dich für den Kauf eines Glücksorte-Buches entscheiden würdest. Ich verpacke es auch persönlich – versprochen. Lass mich im Kommentarfeld wissen, wie viele Bücher du gerne hättest und an welche Adresse ich sie dir senden darf. Ich freue mich auch über Inputs und Ideen für weitere Glücksorte. Vielleicht gibt es ja bald mal eine zweite Auflage ;) Bis dahin: Have fun!
                  </p>
                </div>
              </div>
            </div>

            <div className="relative flex w-full items-center justify-center">

              <div
                className='relative w-full flex items-center justify-center'
              >
                <Formular />
              </div>

            </div>
          </div>
        </div>

        <TwoColCTA
          title='Du brauchst auch ein Buch?'
          text="Falls du gerade kein Bedarf an einem Buch hast, schreibe ich auch Texte und Blog-Beiträge."
          btnLabel='Laila schreib!'
        />

      </div>

    </div>
  )
}

export default IndexPage;

export const buchQuery = graphql`
fragment blogImage on File {
    childImageSharp {
        gatsbyImageData(
            placeholder: BLURRED
        )
    }
  }
  query {

    cover:   file(relativePath: { eq: "gluecksorte/gluecksorte-luzern-cover.jpg" }) {
      ...blogImage
    }

    image1:   file(relativePath: { eq: "gluecksorte/gluecksorte-in-luzern-Bellerive.jpg" }) {
      ...blogImage
    }

    image2:   file(relativePath: { eq: "gluecksorte/gluecksorte-in-luzern-Bergstrasse.jpg" }) {
      ...blogImage
    }

    image3:   file(relativePath: { eq: "gluecksorte/gluecksorte-in-luzern-Parterre.jpg" }) {
      ...blogImage
    }

    image4:   file(relativePath: { eq: "gluecksorte/gluecksorte-in-luzern-Waldstaetterweg.jpg" }) {
      ...blogImage
    }

    image5:   file(relativePath: { eq: "gluecksorte/gluecksorte-in-luzern-Zoepfli-laila-schreibt.jpg" }) {
      ...blogImage
    }

  }
`
